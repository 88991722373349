<template>
  <div class="submitEnding-index">
    <div class="submitEnding-wrap">
      <div class="headTop">
        <p>微信支付</p>
        <p>二维码有效时长为5分钟，请尽快支付</p>
      </div>
      <div class="submit_img">
        <div class="imgbox">
          <div class="erweima">
            <div class="qrcode" ref="qrcodeContainer"></div>
          </div>
          <div class="incon">
            <i class="iconfont icon-xingzhuang saomiao" />
            <p>
              <span
                >请使用<span
                  style="color:#00aaa6;padding-left:4px;padding-right:4px"
                  >微信</span
                >扫一扫</span
              ><br />扫描二维码支付
            </p>
          </div>
        </div>
        <div class="imgbox">
          <img
            class="rightImg"
            src="@/assets/submitEnding/位图@2x.png"
            alt=""
          />
        </div>
      </div>
      <div>
        <a class="endText" @click="gotoMyorder">
          支付完成，去转单
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { payInfo } from "./../api.js";
export default {
  data() {
    return {};
  },

  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.qrcode();
    });
    if (this.$route.query.code) {
      let that = this;
      that.timer = window.setInterval(() => {
        setTimeout(function() {
          that.flesh();
        }, 0);
      }, 10000);
    }
  },
  // 离开页面 销毁timer
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  methods: {
    // 刷新二维码
    flesh() {
      let code = this.$route.query.code;
      let data = {
        orderCode: code
      };
      payInfo(data).then(res => {
        // console.log("res", res);
        if (res.data.code == 0) {
          this.$router.push({
            path: "/marketActivityResult"
          });
        }
      });
    },
    // 跳转页面
    gotoMyorder() {
      this.$router.push({
        path: "/marketActivityResult"
      });
    },
    // 生成二维码
    qrcode() {
      let qrcode = new QRCode(this.$refs.qrcodeContainer, {
        width: 180,
        height: 180,
        padding: 10,
        text: this.$route.query.payInfo, // 二维码地址
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: 3
      });
    }
  }
};
</script>
<style lang="less" scoped>
.submitEnding-index {
  position: relative;
  background: #f7f7f7;
  overflow: hidden;

  .submitEnding-wrap {
    width: 1188px;
    height: 490px;
    background: #fff;
    margin: 16px auto 40px;
    text-align: center;
    overflow: hidden;
    .headTop {
      margin-top: 43px;
      margin-bottom: 38px;
      p:first-child {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        margin-bottom: 8px;
      }
      p:last-child {
        font-size: 12px;
        font-weight: 400;
        color: #777777;
        line-height: 17px;
      }
    }
    .submit_img {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .imgbox {
        width: 204px;
        height: 236px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 56px;
        .incon {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            text-align: left;
          }
          .saomiao {
            color: #00aaa6;
            font-size: 26px;
            margin-right: 8px;
          }
        }
        .erweima {
          width: 180px;
          height: 180px;
          margin-bottom: 12px;
          div {
            width: 180px;
            height: 180px;
          }
        }
        .rightImg {
          width: 204px;
          height: 236px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .imgbox:first-child {
        margin-right: 68px;
      }
    }
    .endText {
      text-decoration: underline;
      font-size: 16px;
      font-weight: 400;
      color: #00aaa6;
    }
  }
}
</style>

