import http from "@/utils/request";

// 获取认购信息
export function getProinfo(data) {
    return http({
        url: '/marketActivity/settlementSimple.nd',
        method: 'post',
        data:data
    })
}
// 普通认购
export function save(data) {
    return http({
        url: '/marketActivity/save.nd',
        method: 'post',
        data:data
    })
}

// 套购认购
export function saveMany(data) {
    return http({
        url: '/marketActivity/saveMany.nd',
        method: 'post',
        data:data
    })
}

// 获取支付信息


export function payInfo(data) {
    return http({
      method: "get",
      url: "/marketActivity/payStatus.nd",
      params:data
    });
  }
// 获取供应商下拉
export function getOrgList(data) {
    return http({
      method: "get",
      url: "/marketActivity/querySupplier.nd",
      params: data
    });
}